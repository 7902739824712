.dashboard-header-wrapper {
  color: #fff !important;
  position: relative;
  .bg-gradient {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  .image-wrapper {
    .img {
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      white-space: normal;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: top center;
      overflow: hidden;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  .content-wrapper {
    padding: 2rem 0 0 0;
    width: 100%;
    position: relative;
    z-index: 2;
    @media (min-width: 1024px) {
      padding: 2.5rem 0;
    }

    .page-header__title {
      font-size: 0.75rem;
      font-weight: 900;

      @media (min-width: 768px) {
        @include typography(h800);
      }
    }

    .page-header__quote {
      margin-top: 12px;

      @media (min-width: 768px) {
        margin-top: 24px;
      }
    }

    .page-header__quote-inner {
      @include typography(h700);
      font-weight: 900;

      @media (min-width: 768px) {
        @include typography(h400);
      }
    }

    .page-header__content {
      padding: 1.8rem 0 0.6rem 0;
    }

    .greeting-wrapper {
      display: block;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }

      .doughnut-wrapper {
        padding: 40px 0;

        @media (min-width: 768px) {
          padding: 20px 0;
        }

        .doughnut {
          height: 10.5rem;
          width: 10.5rem;
          color: rgb(100, 200, 250);

          @media (min-width: 768px) {
            height: 12.375rem;
            width: 12.375rem;
          }

          @media (min-width: 1200px) {
            height: 10.5rem;
            width: 10.5rem;
          }
        }

        .marksNumber {
          line-height: 1.625rem;
          padding-top: 1rem;

          .marks-data {
            font-size: 0.75rem;
            text-transform: uppercase;
            color: #fff;
            display: block;
          }
        }
      }

      .greetings {
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;

          @media (min-width: 1200px) {
            padding-right: 5rem;
          }
        }

        h2 {
          @media (min-width: 768px) {
            font-size: 48px;
          }

          @media (min-width: 1200px) {
            @include typography(h300);
          }
        }

        p {
          padding: 1.125rem 0 2rem 0;
          @media (min-width: 1200px) {
            padding: 1.125rem 0 0rem 0;
          }
        }
      }
    }

    .pageTitle {
      p {
        @media (min-width: 768px) {
          @include typography(h700);
        }

        @media (min-width: 1200px) {
          @include typography(h600);
        }
      }
    }
  }
}
.content-wrapper.innerpage-title {
  padding: 1.5rem 0 0 0;
}

/* model css */
.custom-modal {
  @media (min-width: 768px) {
    width: 42rem;
    float: right;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    color: black !important;
  }
  .modal__close {
    position: absolute;
    z-index: 9;
  }

  .modal__content {
    margin-top: -3rem;
    // margin-top: 2.1rem;
    .paratitle {
      padding: 2rem 0 0rem 0;
      width: 80%;
    }
    .page-header__content {
      @media (max-width: 540px) {
        top: 1.15rem;
        padding-bottom: 3rem;
      }
      padding-top: 1.25rem;
    }

    .page-header__quote-inner {
      @include typography(h600);
      font-weight: 900;
    }

    .custam-modal-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0 3rem 0;

      .doughnut {
        height: 9rem;
        width: 9rem;
        color: rgb(100, 200, 250);
        float: left;

        .modal-doughnut-text {
          line-height: 26px;
          padding-top: 1rem;

          .modal-text-dyanamic {
            font-size: 12px;
            text-transform: uppercase;
            color: #000;
          }
        }
      }
      @media (min-width: 768px) {
        .modal-doughnut {
          width: 30%;
        }

        .doughnut-details {
          width: 70%;
        }
      }
    }
  }
}

/* header Nav */
.dashBoardNavBar {
  .split-column {
    //  display: flex;
    align-items: center;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }

    .downloadButton {
      svg {
        width: 1.25rem;
      }
    }

    .link-icon {
      width: 100%;
      text-align: right;
      padding-right: 1.2rem;

      svg {
        width: 1.55rem;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-right: 2px;

        circle {
          fill: transparent;
        }
      }

      .button--link.button--icon .button__text {
        padding-left: 0.2rem !important;
        color: #fff;

        //  font-size: 0;
        @media (max-width: 767px) {
          font-size: 0;
        }
      }
    }

    .downloadButton {
      text-align: right;

      @media (max-width: 767px) {
        .button--primary {
          font-size: 0;
          min-width: 0;
          min-height: 0;
          border: 0;
          background: transparent;
          box-shadow: none;
        }
      }
    }
  }
}

/* Dashboard Detail Pages css Changes */
.dashBoardNavBar.text-black .button--link .button__text {
  color: #000 !important;
}
