.didyouknow-wrapper {
  // background: #c80000;
  background: #fff;
  padding: 2.5rem 0;

  .splitcolumn {
    @media (min-width: 768px) {
      gap: 5%;
      background: #fff;
      display: grid;
      grid-template-columns: 47.5% 47.5%;
      //align-items: center;
    }

    // @media (min-width: 1200px) {
    //   grid-template-columns: 40% 55%;
    // }

    .col-6 {
      margin: 0 -1.5rem;

      @media (min-width: 768px) {
        margin: 0;
      }

      .body-copy {
        padding: 0 1.5rem 2rem;
        @media (min-width: 768px) {
             padding: 0 1.5rem 3rem;
        }
      }

      p {
        line-height: 1.5;
      }
    }

    .col-5 {
        margin: 0 -1.5rem;

      @media (min-width: 768px) {
        margin: 0;
      }
      @media (min-width: 1200px) {
        display: grid;
        // grid-template-columns: 40% 50%;
       // align-items: center;
      }
         .body-copy {
        padding: 0 1.5rem 1rem;
        @media (min-width: 768px) {
             padding: 0 1.5rem 3rem;
        }
      }

      .col-2 {
        padding: 2rem 0rem;
      }
    }
  }
}
