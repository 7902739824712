.appliedskill-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  h2.feedBack {
    @include typography(h700);
    font-weight: normal;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 20px;
    @media (min-width: 768px) {
      @include typography(h600);
      // padding-top: 7.5rem;
    }
    @media (min-width: 1200px) {
      //  @include typography(h600);
      padding-top: 7.5rem;
    }
  }
  .gridcontainer {
    display: block;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 47% 47%;
      column-gap: 6%;
    }
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    // border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 40px 0px;
    @media (min-width: 1200px) {
      padding: 2.5rem 0px;
    }
    .icons-list {
      margin: 0 auto;
      padding: 5px 0 20px 0;
      display: flex;
      justify-content: center;

      @media (min-width: 1200px) {
        padding: 0rem 2.5rem 1.25rem 0rem;
        display: block;
        float: left;
        height: 100%;
      }
      img {
        @media (min-width: 768px) {
          width: 5.8rem;
        }
      }
    }
    h2 {
      @include typography(h700);
      // padding-bottom:1.6rem;
      text-align: center;
      @media (min-width: 1200px) {
        @include typography(h500);
        text-align: left;
      }
    }
    p {
      padding: 1rem 0;
    }
    .marks {
      padding-top: 0;
      text-transform: uppercase;
      font-size: 0.75rem;
      //   border-bottom: 1px solid rgb(229, 229, 229);
    }
    .feedback-column {
      ul {
        display: flex;
        justify-content: space-between;
        grid-template-columns: 49.5% 49.5%;
        border-bottom: 1px solid rgb(229, 229, 229);
        padding: 1.2rem 0;
        border-top: 1px solid rgb(229, 229, 229);
        &:last-child {
          // border: 0px
        }
        li {
          padding: 0;
          margin: 0;
          @include typography(h700);
          &:before {
            content: "";
            display: none;
          }
          &:last-of-type {
            font-weight: 900;
          }
        }
      }
    }
    //         .button--icon {
    //            position:relative;
    //             .button__text {
    //                 padding-left: 0rem!important;
    //                 padding-right: 1.5rem!important;
    //                 font-weight: 300;
    //             }
    //             svg {
    //                                width: 1rem;
    // float: right;
    // padding-right: 0rem;
    // position: absolute;
    // right: 0;
    //             }
    //         }
    // li {
    //     list-style: none;
    //     padding-top: 5.438rem;
    // }
  }
}

.appliedskill-wrapper .grid-item .feedback-column:last-of-type ul {
  border-bottom: 0px !important;
}

.appliedskill-wrapper.container-pef:before {
  border-right: 3px solid #f7a600;
  content: "";
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  min-height: 50px;
  position: absolute;
  right: 50%;
  top: -0.9%;
  width: 3px;
  z-index: 1;
  @media (min-width: 786px) {
    top: -1.5%;
  }
  @media (min-width: 1200px) {
    top: -3.5%;
    min-height: 5.7rem;
  }
}
