.ExamTitle h1 .page-header__title {
  @include typography(h700);
  text-transform: capitalize;
  letter-spacing: normal;
}

.exam-container {
  display: block;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 1.5rem 0;
  cursor: pointer;
  &:last-of-type {
    border: 0px;
  }
  .col-1 {
    padding-bottom: 1.5rem;
    .avatar__initials {
      @include typography(h800);
    }
  }
  .col-2 {
    width: auto;
    display: flex;
    padding-bottom: 1.5rem;
    color: get-color(black);
    @include typography(h700);
    //font-family: Avenir-Black;
    font-weight: 900;
    //line-height: 24px;
  }
  .result-data {
    display: flex;
    float: left;
    // width: 50%;
    color: get-color(grey-copy);
    padding-left: 12px;
    @include typography(h800);
    font-weight: 900;
  }
  .col-4 {
    display: inline-block;
    color: #2d2d2d;
    @include typography(h800);
    font-weight: 300;
    // float: left;
    width: auto;
  }
  .exam-container :last-child {
    border: none;
  }
}

.Exam-wrapper .card__inner a {
  text-decoration: none;
}

.Exam-wrapper .card__inner a:first-child {
  padding-top: 0;
}

.Exam-wrapper .card__inner a:last-child {
  padding-bottom: 0;
  border-bottom: 0px;
}

.Exam-wrapper .card__inner a:last-child .exam-container {
  border: 0;
}

.col-3 svg.iconLeft {
  width: 1rem;
}

.Exam-wrapper .col-5 {
  float: right;
  color: #000;
}

.Exam-wrapper svg.rightIcon {
  width: 1.25rem;
}

.Exam-wrapper .col-3 {
  display: flex;
  float: left;
  padding-top: 3px;
  width: 60%;
  padding-right: 1rem;
  @media (min-width: 768px) {
    width: 75%;
    padding-right: 2rem;
  }
  @media (min-width: 1200px) {
    width: 18%;
  }
}

@media (min-width: 768px) {
  .exam-container {
    .page-header__content {
      @include typography(h600); //32px
      .page-header__title {
        @include typography(h500); //24px
      }
    }
  }
  .ExamTitle h1 .page-header__title {
    @include typography(h600); //24px
  }
}

@media (min-width: 1200px) {
  .exam-container {
    display: flex;
    .page-header__content {
      @include typography(h600); //32px
      // font-size:32px !important;
    }
    .col-1 {
      padding-bottom: 0;
    }
    .col-2 {
      width: 65%;
      display: flex;
      padding-left: 1.5rem;
      color: black;
      font-size: 20px;
      //font-family: Avenir-Black;
      font-weight: 900;
      padding-bottom: 0;
    }
    .result-data {
      display: flex;
      //  width: 20%;
      color: #2d2d2d;
      // font-family: Avenir-Black;
      font-weight: 900;
      padding-left: 0.85rem;
      float: none;
    }
    .col-4 {
      display: flex;
      color: #2d2d2d;
      font-weight: 300;
      float: none;
      width: 22%;
      padding-left: 0.85rem;
    }
    .exam-container :last-child {
      border: none;
    }
    .col-3 {
      padding-top: 0px;
    }
  }
}
