@import "~acca-design-system/dist/es/acca-design-system.css";
@import "~acca-design-system/src/styles/app.scss";
@import "./ExamPerformance.scss";
@import "./LandingPage.scss";
@import "./Appliedskill.scss";
@import "./Dashboard.scss";
@import "./Navbar.scss";
@import "./ErrorPage.scss";
@import "./Accordian.scss";
@import "./global.scss";
@import "./Helpsupport.scss";
@import "./Nextsteps.scss";
@import "./Passrate.scss";
@import "./DidyouKnow.scss";
// @import "./LearningSupport.scss";
// html{
//  // font-size: 62.5%;
// }
body {
  padding: 0;
  margin: 0;
  background: get-color(grey-10);
  overflow-x: hidden;
}
// .main-Wrapper{
//   max-width: 1184px;
//   margin: 0 auto;
// }
.grid-container.fluid {
  padding: 0;
}
