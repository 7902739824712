.bttn-links {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  .anchor {
    font-weight: 700;
  }
}

.body-copy a {
  border-bottom: 0.125rem dotted #fff;
  padding-bottom: 0.2rem;
}

.column-layoutsplit.detailpage-wrapper {
  position: relative !important;
}

.doughnut {
  height: 10.5rem;
  width: 10.5rem;
  color: rgb(100, 200, 250);
  //    margin-top: 2.5rem;

  .marksNumber {
    line-height: 1.625rem;
    padding-top: 0.5rem;
    color: #2d2d2d;

    .marks-data {
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #2d2d2d;
    }
  }
}

.container-pef {
  padding: 0 1.5rem;
  max-width: 80rem;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding: 0 2rem;
  }
  @media (min-width: 1200px) {
    padding: 0 4rem;
  }
}

@media (min-width: 1024px) {
  .column-layoutsplit .sidebar__inner {
    z-index: 1;
  }
}
.loading-dots {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
}

.bttn-links.container-pef {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 4rem;
}

@media (max-width: 767px) {
.bttn-links.container-pef a:nth-child(1) {
    margin: 2rem 0;
}
.bttn-links.container-pef a:nth-child(2){
    position: absolute;
    right: 1.5rem;
    top: 7rem;
}
}

 .desktop-image {
      width: 100%;
      height:100%;
      background-size: cover;
      display:none;
      background-repeat: no-repeat;
      background-position: center;
   
     @media (min-width: 1200px) {
    
       display:block;
     }
     }
     .mobile-image{
      display:block;
       @media (min-width: 1200px) {
      
       display:none;
     }

   }