/*footer */

section#footer-top {
  display: none;
}

.help-container {
  padding-top: 1.5rem;
  min-height: 16rem;
  overflow: hidden;
  img:hover {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: #000;
    width: 50%;
  }
}

.help-container-column1 {
  // @media (min-width: 1200px) {
  //   width: 100%;
  //   min-height: 16rem;
  //   overflow: hidden;
  //   position: absolute;
  //   display: block;
  //   white-space: normal;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   /* min-width: 101%; */
  //   max-width: none;
  //   /* min-height: 20%; */
  //   height: 100%;
  //   width: 100%;
  //   z-index: 1;
  //   padding-right: 2rem;
  //   padding-bottom: 1.4rem;
  // }
}

.help-wrapper h3 {
  padding-top: 3.75rem;
}

.help-container-column1 {
  padding-bottom: 1.25rem;
  position: relative;

  .img-content {
    position: absolute;
    bottom: 1.8rem;
    z-index: 9;
    padding: 1.5rem;
  }
}

.column-report,
.column-report2 {
  border: 1px solid #ccc;
  padding: 2rem;
  min-height: 16.6rem;
  margin-bottom: 1.25rem;
  background: #fff;
}

.column-report p,
.column-report2 p {
  padding-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .help-container-column2 {
    display: flex;
    column-gap: 2%;
    /* width: 45%; */
  }

  .help-wrapper h3 {
    padding-top: 5rem;
  }
}

@media (min-width: 1200px) {
  .help-container {
    width: 100%;
    display: flex;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .help-container-column1 {
    width: 50%;
    padding-right: 1.25rem;
  }

  .column-report,
  .column-report2 {
  }

  .help-container-column2 {
    display: block;
    width: 50%;
  }
}
