.column-layoutsplit {
  background: #fafafa;
  @media (max-width: 1023px) {
    position: absolute;
    top: 0;
  }
  .navbar-wrapper {
    &.bg-white {
      .nav-bar {
        background-color: #fff;
        transition: transform 0.2s;
        transition: 0.1s ease-in-out;
        transform: translate3d(0, 0%, 0);
        .nav-bar__menu-btn {
          color: #000;
          .hamburger__stroke {
            background-color: #000;
          }
        }
        .nav-bar__links {
          li {
            &:first-child {
              svg {
                // width: 22px;
                border: 1px solid #000;
                border-radius: 30px;
                circle {
                  fill: transparent;
                }
              }
            }
            svg {
              width: 1.5rem;
              height: 1.5rem;
              circle {
                fill: transparent;
              }
              path {
                fill: #000;
              }
            }
          }
          a {
            padding: 0 0.8rem;
          }
        }
      }
    }
    .nav-bar {
      background-color: transparent;
      border-bottom: 0px;
      //transition: 0.1s ease-in-out;
      .nav-bar__menu-btn {
        color: #fff;
        .hamburger__stroke {
          background-color: #fff;
          transition: 0.1s ease-in-out;
        }
      }
    }
    .nav-bar__links {
      li {
        &:first-child {
          svg {
            // width: 22px;
            border: 1px solid #fff;
            border-radius: 30px;
            circle {
              fill: transparent;
            }
          }
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
          circle {
            fill: transparent;
          }
          path {
            fill: #fff;
          }
        }
      }
      a {
        padding: 0 0.8rem;
      }
    }
  }
}
