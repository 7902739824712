.accordian-tab {
 .padding-tm {
    padding: 0rem 0 3rem 0;
     @media (min-width: 768px) {
       padding: 2rem 0 0rem 0;
     }
}
  .row {
    padding: 3rem 0;
    border-bottom: 1px solid rgb(220, 220, 220);

    @media (min-width: 768px) {
      padding: 4rem 0;
      position: relative;
    }

    .col-2 {
      @media (min-width: 768px) {
        display: inline-block;
        width: 60%;
      }

      h4 {
        @include typography(h600);
        padding: 1rem 0 2.5rem 0;

        @media (min-width: 768px) {
          @include typography(h500);
          padding: 0;
        }

        @media (min-width: 1024px) {
          @include typography(h600);
        }

        @media (min-width: 1200px) {
          @include typography(h500);
        }
      }

      .clockIcon {
        svg {
          vertical-align: text-top;
          width: 1rem;
          display: inline-block;
          margin: 0 0.6rem 0 0rem;
        }
      }
    }

    .col-1 {
      @media (min-width: 768px) {
        width: 15%;
        display: inline-block;
        height: 100%;
        vertical-align: top;
      }

      .avatar {
        width: 5rem;
        height: 5rem;
        @include typography(h400);

        .avatar__initials {
          @include typography(h400);
        }
      }
    }

    .col-4 {
      @media (min-width: 768px) {
        position: absolute;
        top: 4rem;
        right: 0;
        width: auto;
      }

      .doughnut {
        height: 10.5rem;
        width: 10.5rem;
        color: rgb(100, 200, 250);
        margin-top: 0.5rem;

        @media (min-width: 768px) {
          margin-top: 0rem;
          float: right;
        }
      }
    }

    .marks-data {
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #2d2d2d;
    }

    .col-3 {
        margin-top: 2rem;
        .show-hide-panel__content{
          p{
            padding-bottom:1rem;
            a{
                  border-bottom: 0.125rem dotted #000;
                  padding-bottom: 0.1rem;
                  color: #000;
                  text-decoration: none;
            }
          }

        }
      @media (min-width: 768px) {
        padding-left: 15%;
        margin-top: 9rem;
      }

      @media (min-width: 1200px) {
        margin-top: 3rem;
        width: 72%;
      }

      .show-hide-panel__control {
        padding: 2.5rem 1.5rem;
        justify-content: space-between;
        align-items: center;
        display: flex;
  @media (min-width: 768px) {
     padding: 2.5rem 3rem;
  }
        .show-hide-panel__status {
          margin-top: 0;
        }
      }
    }
  }
  .column-reverseMobile {
    display: flex;
    flex-direction: column-reverse;
      @media (min-width: 1200px) {
            display: block;
    flex-direction: column;
      }
}
}

.column-layoutsplit.detailpage-wrapper > div:nth-last-child(2) .row {
    border: 0;
}
.timemgm-space {
  position:relative;
   @media (min-width: 768px) {
     top: 7rem !important;
   }
  h3{
     position: absolute;
     width:100%;
        top: -1rem;
      left: 0rem;
    @media (min-width: 768px) {
      top: -2.6rem;
    }
  }
 
}
// @media only screen and (min-width: 768px) and (max-width: 1024px) {
//   .no-marginTablet .doughnut {
//     margin-top: 1rem !important;
//   }
// }
