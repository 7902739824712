.passrate-Wrapper {
 background: #fff;
  @media (min-width: 768px) {
   // margin: 0 2rem;
  }
  @media (min-width: 1024px) {
    background: #fff;
    margin: 0;
  }
  padding: 2.5rem 0;
  .splitcolumn {
       background: #000;
    margin:0 -1.5rem;
      @media (min-width: 768px) {
        margin:0;
      }
  
    a{
   text-decoration: none;
    @media (min-width: 768px) {
      display: flex;
      column-gap: 6%;
      padding: 2rem 1.5rem;
      @media (min-width: 1024px) {
        padding: 3rem 2rem;
      }

      @media (min-width: 1200px) {
        padding: 4rem 4rem;
      }
    }

    .col-3 {
      @media (min-width: 1024px) {
        width: 50%;
      }
      .progressbartab {
        display: flex;
        align-items: center;
      }
      .progress-indicator__line-inner {
        background-color: #f7a600;
      }
    }
    .col-9 {
      .body-copy{
        padding:1.5rem 1.5rem;
         @media (min-width: 1024px) {
          padding:0;
         }
      }
      @media (min-width: 1024px) {
        width: 50%;
      }
    }
  }
}
}
