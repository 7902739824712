.landing-pageheader .page-header__content {
  padding: 2.625rem 0 1.5rem 0;
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.landing-pageheader .page-header__quote-inner {
  @include typography(h500);
  text-transform: capitalize !important;
}

.landing-pageheader .subTitle {
  @include typography(h700);
  color: rgb(45, 45, 45);
  line-height: 1.5;
}

.Exam-wrapper {
  .Card_result {
    padding: 0 1.5rem;
    .card__inner {
      flex-wrap: nowrap;
      padding: 1.5rem 0;
    }
  }
}

@media (min-width: 768px) {
  .landing-pageheader .page-header__quote-inner {
    @include typography(h400);
  }
  .Exam-wrapper {
    .page-header__quote-inner {
      @include typography(h600); //32px
    }
    .page-header__content {
      padding: 80px 0 32px 0;
      @include typography(h600); //32px
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .Card_result {
      padding: 0 2rem;
      .card__inner {
        flex-wrap: nowrap;
        padding: 2rem 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .landing-pageheader .page-header__quote-inner {
    @include typography(h500);
  }
  .Exam-wrapper {
    .page-header__content {
      padding: 5rem 0 1.5rem 0;
    }
    .Card_result {
      padding: 0 4rem;
      .card__inner {
        padding: 3.5rem 0;
      }
    }
  }
}
