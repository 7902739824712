.error-wrapper {
  padding: 1.5rem 1.5rem 5rem 1.5rem;
  @media (min-width: 768px) {
    padding: 2rem 2rem 14rem 2rem;
  }
  @media (min-width: 1200px) {
    padding: 4rem 4rem 5rem 4rem;
  }
  .error-container {
    background: #fff;
    padding: 1.5rem 1rem 1.5rem;
    @media (min-width: 768px) {
      padding: 2rem 2rem 2rem;
    }
    @media (min-width: 1200px) {
      padding: 2.5rem 4rem 2.5rem;
    }
    h2 {
      @include typography(h700);
      padding: 2rem 0 0 0;
    }
    h3 {
      @include typography(h500);
      padding: 0.9rem 0 0rem 0;
      @media (min-width: 768px) {
        font-size: 3rem !important;
      }
      @media (min-width: 1200px) {
        @include typography(h300);
      }
    }
    h4 {
      @include typography(h700);
    }
    p {
      @include typography(h700);
    }
    .paratext {
      padding: 4rem 0;
    }
    .textWrap {
      @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .paratext2 {
        padding: 1.5rem 0;
        @media (min-width: 768px) {
          padding: 1.5rem 3rem 1.5rem 0;
        }
      }
    }
  }
  div[aria-label="animation"] {
    width: 17.563rem !important;
  }
}
