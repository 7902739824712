.nextsteps-Wrapper {
  background: #fff;
  padding: 2.5rem 0;

  .splitcolumn {
    background: #000;
    @media (min-width: 1200px) {
      display: flex;
      min-height: 16rem;
    }
    .col-3 {
      position: relative;
      .img{
        width:100%;
      }
      @media (min-width: 1200px) {
        width: 40%;
      }
      &:after {
      }
      .img {
        @media (min-width: 1024px) {
          width: 100%;
          min-height: 16rem;
          overflow: hidden;
          position: absolute;
          display: block;
          white-space: normal;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* min-width: 101%; */
          max-width: none;
        background-position: top center;
        background-size: cover;
          height: 100%;
          width: 100%;
          z-index: 1;
        }
      }

      .img-content {
        position: absolute;
        top: 50%;
        z-index: 9;
        left: 50%;
        
        /* width: 100%; */
        transform: translate(-50%, -50%);
        @media (min-width: 1200px) {
          top: 50%;
          bottom: auto;
        }
      }
    }

    .col-9 {
      @media (min-width: 1200px) {
        width: 60%;
      }

      .paraContainer {
        padding: 3rem 1.5rem;

        @media (min-width: 768px) {
          padding: 3rem 3rem;
        }

        @media (min-width: 1024px) {
          padding: 2rem 4rem;
        }
        @media (min-width: 1200px) {
          padding: 4rem 6rem;
        }
        a{
          color: #fff;
          margin-bottom: 1rem;
          display: inline-block;
        }
      }
    }
  }
  .col-3:after {
    border-left: 3px solid #f7a600;
    content: "";
    /* left: 95%; */
    margin-left: auto;
    margin-right: auto;
    min-height: 5rem;
    position: absolute;
    right: 50%;
    bottom: -33px;
    top: auto;
    width: 3px;
    z-index: 1;
    @media (min-width: 1200px) {
      border-left: 3px solid #f7a600;
      content: "";
      /* left: 95%; */
      margin-left: auto;
      margin-right: auto;
      min-height: 6.25rem;
      position: absolute;
      right: 0;
      top: 43%;
      bottom: auto;
      transform: translate(-50%, -50%);
      transform: rotate(90deg);
    }
  }
}
